import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { NavigationBar, StickyBottomView } from '@/components/useCases';
import Header from '@/pages/login/components/Header';
import Description from '@/pages/login/components/Description';
import { Button } from '@/components/forms';
import { useAuthContext } from '@/context/auth';
import { COLORS } from '@/styles/colors';
import Template from '@/components/useCases/Template';
import amplitude from '@/utils/amplitude';

import KakaoIcon from '../../assets/icons/ic_kakaotalk.svg';
import LeftArrow from '../../assets/icons/ic_arrow_left_regular.svg';
import * as styles from './styles';

const Login = () => {
  const { signIn } = useAuthContext();
  const router = useRouter();

  useEffect(() => {
    amplitude.track('untact_addOnLogin_view');
  }, []);

  return (
    <Template.Page className={styles.container}>
      <NavigationBar
        leftComponent={
          <LeftArrow
            width={24}
            height={24}
            color={COLORS.GRAY_90}
            className={styles.backIcon}
            onClick={() => router.back()}
            alt="굿닥 비대면진료 뒤로가기"
          />
        }
      />
      <div className={styles.topContainer}>
        <Header />
        <Description />
      </div>
      <StickyBottomView>
        <Button
          id={'start_kakaologin_addon'}
          size={'large'}
          className={styles.button}
          styleType={'yellowFilled'}
          onClick={signIn}
        >
          <KakaoIcon width={20} height={20} alt="굿닥 비대면진료 카카오" />
          {'카카오로 시작하기'}
        </Button>
      </StickyBottomView>
    </Template.Page>
  );
};

export default Login;
