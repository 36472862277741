import { css } from '@emotion/css';

import { COLORS } from '@/styles/colors';

export const messageBox = css`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 24px;
  border-radius: 8px;
  background-color: ${COLORS.BLUE_20};
`;
