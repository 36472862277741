import { css } from '@emotion/css';

export const container = css`
  display: flex;
  flex-direction: column;
  padding: 36px 0;
  row-gap: 20px;
`;

export const title = css`
  display: flex;
  flex-direction: column;
`;
