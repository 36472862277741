import { COLORS } from '@/styles/colors';
import { Text } from '@/components/typographies';

import Logo from '../../../../assets/images/img_logo_goodoc.svg';
import * as styles from './styles';

const Header = () => {
  return (
    <div className={styles.container}>
      <Logo width={120} height={36} />
      <div className={styles.title}>
        <Text type={'heading3_700'} color={COLORS.GRAY_90}>
          3초 안에 로그인하고
        </Text>
        <Text type={'heading3_700'} color={COLORS.GRAY_90}>
          진료 신청을 완료하세요
        </Text>
      </div>
    </div>
  );
};

export default Header;
