import { Text } from '@/components/typographies';
import DescriptionRow from '@/pages/login/components/Description/components/DescriptionRow';
import { COLORS } from '@/styles/colors';

import * as styles from './styles';

const Description = () => {
  return (
    <div className={styles.messageBox}>
      <Text type={'body1_500'} color={COLORS.GRAY_80}>
        로그인 후에
      </Text>
      <DescriptionRow text={'대기 시간을 확인할 수 있어요'} />
      <DescriptionRow text={'처방전을 확인할 수 있어요'} />
      <DescriptionRow text={'간단하게 다시 진료보세요'} />
    </div>
  );
};

export default Description;
