import { FC } from 'react';

import { Text } from '@/components/typographies';
import { COLORS } from '@/styles/colors';

import CheckIcon from '../../../../../../assets/icons/ic_check.svg';
import * as styles from './styles';

type DescriptionRowProps = {
  text: string;
};

const DescriptionRow: FC<DescriptionRowProps> = (props) => {
  const { text } = props;

  return (
    <div className={styles.container}>
      <CheckIcon
        width={20}
        height={20}
        color={COLORS.ALPHA_BLUE_40}
        alt="굿닥 비대면진료 체크"
      />
      <Text type={'body1_700'} color={COLORS.GRAY_90}>
        {text}
      </Text>
    </div>
  );
};

export default DescriptionRow;
