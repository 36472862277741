import { css } from '@emotion/css';

import { COLORS } from '@/styles/colors';

export const container = css`
  background-color: ${COLORS.WHITE};
`;

export const backIcon = css`
  cursor: pointer;
`;

export const topContainer = css`
  flex: 1;
  padding: 0 24px;
`;

export const button = css`
  width: 100%;
  gap: 4px;
`;
